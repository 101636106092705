import React from 'react'

import {
  FormGroup,
  Help,
  Input,
  Label,
  Link,
  List,
  ListItem,
  Paragraph
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { FormLayoutWrapper } from '../../components/FormLayoutWrapper'
import { Section } from '../../components/Section'
import { SectionHeading } from '../../components/SectionHeading'
import LabelHTMLExample from '../../examples/html/LabelHTMLExample'
import { LabelExample } from '../../examples/react/LabelExample'
import { LabelRequiredExample } from '../../examples/react/LabelRequiredExample'
import { LabelSublabelExample } from '../../examples/react/LabelSublabelExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Label"
    components={[{ component: Label }]}
    status={[{ type: 'accessible', version: '14.4.2' }]}
  >
    <Section>
      <Help>
        <Paragraph noMargin>
          Käytä <Code>Label</Code>
          -komponenttia vain erikoistilanteisiin.
          <Paragraph noMargin>Tavallisimmat korvaavat vaihtoehdot:</Paragraph>
          <List variant="unordered">
            <ListItem>
              tavalliset lomakekomponentit (esim. <Code>Input</Code>,{' '}
              <Code>DateInput</Code>, <Code>Select</Code>): käytä inputin omaa
              label-proppia
            </ListItem>
            <ListItem>
              read-only-tilainen lomakekomponentti: käytä <Code>Detail</Code>
              -komponenttia
            </ListItem>
            <ListItem>
              otsikko: käytä Heading-komponenttia esim. lomakkeilla{' '}
              <Link href="/lomakkeet/formgroup/#ryhmittely-yhden-otsikon-alle">
                FormGroupin otsikointiin
              </Link>
            </ListItem>
          </List>
        </Paragraph>
      </Help>
      <List variant="unordered">
        <ListItem>
          Lomakekenttien täyttämiseen liittyvä ohjeistus pyritään tarjoamaan
          kentän <Code>sublabel</Code>issa.
        </ListItem>
        <ListItem>
          <Code>Help</Code>-proppia käytetään vain kentän täyttämisen kannalta
          ei-välttämättömän taustatiedon tarjoamiseen.
        </ListItem>
        <ListItem>
          Placeholderissa ei pidä toistaa samaa sisältöä, joka on jo annettu
          sublabelissa. Esimerkiksi aika- tai päivämääräformaatti ohjeistetaan
          ensisijaisesti vain sublabelissa.
        </ListItem>
        <ListItem>
          Lue tarkemmin labelin käytöstä{' '}
          <a href="../../patternit/lomakekentan-otsikointi/">
            Lomakekentän otsikointi
          </a>
          -patternista.
        </ListItem>
      </List>
    </Section>
    <Section title="Useamman input-kentän yhteinen label">
      <Paragraph>
        Kieliversioitu kenttä on tyypillinen tapaus, jossa <Code>Label</Code>
        -komponenttia tarvitaan: kentillä on yhteinen label, joka luetaan{' '}
        <Code>FormGroup</Code>iin siirtyessä.
      </Paragraph>
      <Playground
        example={LabelExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>
    <Section title="Pakollisuus">
      <Paragraph>
        Huomaa, että required on määriteltävä erikseen proppina sekä Labelille
        että Inputille. Allaolevassa esimerkissä on kaksi kenttää, toisessa
        manuaalinen pakollisuuden määritys ja toisessa suositeltu tapa käyttäen
        Inputin omia proppeja.
      </Paragraph>
      <Playground
        example={LabelRequiredExample}
        WrapperComponent={FormLayoutWrapper}
      />

      <Playground
        format="html"
        WrapperComponent={FormLayoutWrapper}
      >
        <>
          <LabelHTMLExample
            htmlFor="label-html-example-1"
            required
            subLabel="Sub Label"
            subLabelId="label-html-example-1-sublabel"
          >
            Label
          </LabelHTMLExample>
          <input
            aria-describedby="label-html-example-1-sublabel"
            className="form-control form-control-danger"
            id="label-html-example-1"
            placeholder="Placeholder"
            required
            type="text"
          />
          <div
            aria-live="assertive"
            className="form-text text-danger"
            id="form-text-html-example-1"
          >
            Error
          </div>
        </>
      </Playground>
    </Section>
    <Section title="Sublabel">
      <Paragraph>
        Myös subLabel-teksti tulee linkittää input-kenttään.
      </Paragraph>
      <Playground
        example={LabelSublabelExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>
    <Section title="Eri tavat yhdistää Label ja Input">
      <SectionHeading>
        Sisäänrakennettu label-proppi (suositeltu tapa)
      </SectionHeading>
      <Paragraph>
        Style Guiden input-komponenteissa on sisäänrakennettuna label-proppi,
        joka luo Labelin automattisesti ja linkittää sen input-kenttään oikein.
        Tämä on suositeltava tapa labeloida input-kentät. Erillistä
        Label-komponenttia tarvitsee käyttää vain erikoistapauksissa.
      </Paragraph>
      <Playground WrapperComponent={FormLayoutWrapper}>
        <FormGroup noMargin>
          <Input label="Suositeltu tapa" />
        </FormGroup>
      </Playground>
      <SectionHeading>htmlFor-attribuutti</SectionHeading>
      <Paragraph>
        Natiivissa html:ssä labelin ja inputin linkittäminen tapahtuu{' '}
        <Code>&lt;label&gt;</Code>-elementin <Code>for</Code>-attribuutilla,
        joka viittaa inputin <Code>id</Code>-attribuuttiin. Sama mekanismi on
        mahdollinen myös Style Guiden Label-komponentissa.
      </Paragraph>
      <Playground WrapperComponent={FormLayoutWrapper}>
        <FormGroup noMargin>
          <Label htmlFor="label-example-2">
            Esimerkki labelin ja inputin htmlFor -linkityksestä
          </Label>
          <Input id="label-example-2" />
        </FormGroup>
      </Playground>
      <SectionHeading>aria-labelledBy -linkitys</SectionHeading>
      <Paragraph>
        Toinen tapa on antaa Labelille id ja viitata siihen Inputin
        ariaLabelledby-propilla.
      </Paragraph>
      <Playground WrapperComponent={FormLayoutWrapper}>
        <FormGroup noMargin>
          <Label
            as="p"
            id="special-label"
          >
            Esimerkki labelin ja inputin aria-labelledBy -linkityksestä
          </Label>
          <Input
            id="label-example-1"
            ariaLabelledby="special-label"
          />
        </FormGroup>
      </Playground>
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph noMargin>Tarkistuslista:</Paragraph>
      <List variant="unordered">
        <ListItem>
          Varmista, että Labelia on käytetty yhdessä lomakekentän kanssa, ei
          yksinään.
        </ListItem>
        <ListItem>
          Mieti, voisiko tilanteessa käyttää Style Guiden input-kenttiä ja
          niiden sisäänrakennettua label-proppia. Jos päädyt käyttämään Labelia,
          varmista, että se on linkitetty haluttuun input-kenttään.
        </ListItem>
        <ListItem>
          Varmista, että sublabelissa on lomakekentän täyttämiseen liittyvä
          tieto (älä korvaa sublabelin ohjetta placeholderilla) ja että myös
          sublabel on linkitetty haluttuun input-kenttään.
        </ListItem>
        <ListItem>
          Jos Labelissa on required, tarkista että se on lisätty myös liittyvään
          input-kenttään. Style Guiden input-kentät huolehtivat tämän
          automaattisesti, mutta Labelia käytettäessä merkinnästä tulee
          huolehtia itse.
        </ListItem>
      </List>

      <Paragraph>
        <Code>Label</Code>-komponentti tuottaa html-koodiin{' '}
        <Code>&lt;label&gt;</Code>-elementin, ellei <Code>as</Code>-propilla
        määritetä käytettäväksi jotain muuta html-elementtiä.{' '}
        <Code>&lt;label&gt;</Code> on semanttinen elementti, joka tarkoitettu
        nimikoimaan lomakkeen kenttiä.
      </Paragraph>

      <Paragraph noMargin>Mahdollisia korvaavia komponentteja:</Paragraph>

      <List variant="unordered">
        <ListItem>
          Otsikointi: <Code>Label</Code>in sijaan samankokoinen{' '}
          <Code>&lt;Heading level=4&gt;</Code>-tasoinen otsikko
        </ListItem>
        <ListItem>
          Lomakeosion otsikointi: <Code>FormGroup</Code>in yhteinen otsikko,
          katso{' '}
          <a href="../formgroup/#ryhmittely-yhden-otsikon-alle">
            Ryhmittely yhden otsikon alle
          </a>
          -käyttöesimerkki
        </ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
