import React from 'react'

import { FormGroup, Input, Label } from '@te-digi/styleguide'

const LabelRequiredExample = () => (
  <>
    <FormGroup>
      <Label
        htmlFor="label-required-example"
        required
      >
        Pakollinen label
      </Label>
      <Input
        required
        id="label-required-example"
        value="Input-kenttä, jossa required"
      />
    </FormGroup>
    <FormGroup>
      <Input
        required
        label="Suositeltu vaihtoehto"
      />
    </FormGroup>
  </>
)

export { LabelRequiredExample }
