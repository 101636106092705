import React from 'react'

import { FormGroup, Input, Label } from '@te-digi/styleguide'

const LabelSublabelExample = () => (
  <FormGroup>
    <Label
      htmlFor="label-sublabel-example"
      subLabel="Sublabel antaa tarkempia ohjeita"
      subLabelId="sublabel-id"
    >
      Kentän nimilappu
    </Label>
    <Input
      id="label-sublabel-example"
      ariaDescribedby="sublabel-id"
    />
  </FormGroup>
)

export { LabelSublabelExample }
